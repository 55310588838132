.icon {
    display: inline-block;
    transition: transform 0.3s ease-in-out; /* Animación de transición */
    font-size: 24px; /* Ajusta el tamaño del ícono según sea necesario */
  }
  
  .rotate-true {
    transform: rotate(90deg); /* Flecha apuntando hacia la derecha */
    margin-left: 25px;
  }
  
  .rotate-false {
    transform: rotate(0deg); /* Flecha apuntando hacia la izquierda */
    margin-left: 25px;
  }